<template>
  <v-container class="my-5">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="elevation-2">
          <v-card-title class="headline mb-0">Privacy Policy</v-card-title>
          <v-divider class="mt-3"></v-divider>
          <v-card-text>
            <p class="mb-3"><strong>Privacy Policy</strong></p>

            <p class="mb-3"><strong>1. Login with Google OAuth:</strong></p>
            <p class="mb-3">- Information collected:<br>
              When using Google OAuth for login, we may access the user's basic profile information (name, email address,
              profile picture, etc.).</p>
            <p class="mb-3">- Use of information:<br>
              We use the information obtained through Google OAuth to provide user authentication for login and access
              purposes.</p>
            <p class="mb-3">- Sharing of information:<br>
              We do not share user information with third parties, except when required by law or necessary for the
              provision of our services.</p>

            <p class="mb-3"><strong>2. Web analytics with Google Analytics:</strong></p>
            <p class="mb-3">- Information collected:<br>
              On our website, we use cookies generated by Google Analytics to collect information about user website usage
              (IP address, page views, session duration, etc.).</p>
            <p class="mb-3">- Use of information:<br>
              We use the information collected through Google Analytics for website usage analysis, improvement, and
              generating statistical data.</p>
            <p class="mb-3">- Sharing of information:<br>
              Information collected by Google Analytics is processed in accordance with Google's privacy policy. For more
              details, please refer to Google's privacy policy.</p>

            <p class="mb-3"><strong>3. Use of cookies:</strong></p>
            <p class="mb-3">- Our website may use cookies to enhance website performance and improve user experience.</p>

            <p class="mb-3"><strong>4. Data protection:</strong></p>
            <p class="mb-3">- We take appropriate technical and organizational measures to protect user information. We
              implement access controls, data encryption, and regular security audits to ensure confidentiality and
              security of information.</p>

        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container></template>
  